var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-row",
    { staticClass: "endosso-section" },
    [
      _vm._t("default"),
      _vm.col != 4
        ? _c(
            "div",
            {
              staticClass: "disableddates cursor-pointer ",
              on: {
                click: function($event) {
                  return _vm.setDateChange()
                }
              }
            },
            [
              _c("div", { staticClass: "content cursor-pointer" }, [
                _vm._v("\n      Alterar Datas\n      "),
                _c("i", {
                  staticClass:
                    "onpoint-pencil-simple-line icon-font  float-right text-lg ",
                  staticStyle: { "margin-left": "5px" }
                })
              ])
            ]
          )
        : _vm._e(),
      _vm.showItens.dataInicial
        ? _c(
            "vs-col",
            {
              class: "container-element",
              attrs: { "vs-lg": _vm.col, "vs-sm": "12" }
            },
            [
              _c("p", { staticClass: "label-content label" }, [
                _vm._v(_vm._s(_vm.label.dataInicial))
              ]),
              _c("ValidationProvider", {
                attrs: { name: _vm.label.dataInicial, rules: "required" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _vm.label.dataInicial == "Início vigência da apólice"
                            ? _c("vs-input", {
                                staticClass: "w-full",
                                attrs: {
                                  size: "large",
                                  value: _vm.$utils.dateToDDMMYYYY(
                                    _vm.dataInicial
                                  ),
                                  disabled: true
                                }
                              })
                            : _c("datepicker", {
                                staticClass: "w-full",
                                attrs: {
                                  language: _vm.ptBR,
                                  format: "dd/MM/yyyy",
                                  disabled:
                                    _vm.disabledItens.dataInicial ||
                                    _vm.hasFixedDateStart,
                                  "disabled-dates": _vm.disabledDates
                                },
                                model: {
                                  value: _vm.dataInicial,
                                  callback: function($$v) {
                                    _vm.dataInicial = $$v
                                  },
                                  expression: "dataInicial"
                                }
                              }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: errors[0],
                                  expression: "errors[0]"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [
                              _vm._v(
                                "\n        " + _vm._s(errors[0]) + "\n      "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1709688113
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showItens.dias
        ? _c(
            "vs-col",
            {
              class: "container-element",
              attrs: { "vs-lg": _vm.col, "vs-sm": "12" }
            },
            [
              _c("p", { staticClass: "label-content label" }, [
                _vm._v(_vm._s(_vm.label.dias))
              ]),
              _c("ValidationProvider", {
                attrs: {
                  name: _vm.label.dias,
                  rules:
                    "required|mindays:" +
                    (_vm.minDias || 1) +
                    "|maxdays:" +
                    (_vm.maxDias || false)
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              size: "large",
                              disabled: _vm.disabledItens.dias
                            },
                            on: {
                              blur: function($event) {
                                return _vm.setPrazoDias()
                              }
                            },
                            model: {
                              value: _vm.prazo,
                              callback: function($$v) {
                                _vm.prazo = _vm._n($$v)
                              },
                              expression: "prazo"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: errors[0],
                                  expression: "errors[0]"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [
                              _vm._v(
                                "\n        " + _vm._s(errors[0]) + "\n      "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  491951763
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showItens.dataFinal
        ? _c(
            "vs-col",
            {
              class: "container-element",
              attrs: { "vs-lg": _vm.col, "vs-sm": "12" }
            },
            [
              _c("p", { staticClass: "label-content label" }, [
                _vm._v(_vm._s(_vm.label.dataFinal))
              ]),
              _c("ValidationProvider", {
                attrs: { name: _vm.label.dataFinal, rules: "required" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var errors = ref.errors
                        return [
                          _c("datepicker", {
                            staticClass: "w-full",
                            attrs: {
                              language: _vm.ptBR,
                              format: "dd/MM/yyyy",
                              disabled: _vm.disabledItens.dataFinal,
                              "disabled-dates": _vm.disabledDatesEnd
                            },
                            model: {
                              value: _vm.dataFinal,
                              callback: function($$v) {
                                _vm.dataFinal = $$v
                              },
                              expression: "dataFinal"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: errors[0] || _vm.showErroData,
                                  expression: "errors[0] || showErroData"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(errors[0] || _vm.erroData) +
                                  "\n      "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  173189772
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }